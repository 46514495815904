import {
  Button,
  Form,
  Input,
  Spin,
  notification,
  Upload,
  Image,
  Select,
  ColorPicker,
} from "antd";
import {
  UploadOutlined,
  DeleteOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";

const EventForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const [eventData, setEventData]: any = useState();
  const [loader2, setLoader2] = useState(false);
  const [updatedCoverPhoto, setUpdatedCoverPhoto] = useState("");
  const [deletedImagesIds, setDeletedImagesIds]: any = useState([]);
  const [fileList, setFileList]: any = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [open, setOpen] = useState(false);
  const [colors, setColors]: any = useState(["#707d91"]);
  const [colorsValidation, setColorsValidation] = useState(false);
  const [size, setSize]: any = useState("middle");

  // convert image to show
  const getBase64 = (file: any) => {
    var reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      setUpdatedCoverPhoto(reader?.result);
      // return reader?.result;
    };
    reader.onerror = function (error: any) {
      console.log("Error: ", error);
    };
  };

  const handleSizeChange = (e: any) => {
    setSize(e.target.value);
  };
  const handleChange = (value: string | string[]) => {
    console.log(`Selected: ${value}`);
  };

  //method for API call
  const getEventHandler = () => {
    setLoader2(true);
    // login token
    //axios call
    axios
      .get(
        `${process.env.REACT_APP_FRONTED_URL}/products/${params?.id}?locale=en`
      )
      .then((response) => {
        let duplicateEvent = { ...response?.data?.data };
        console.log("duplicateEvent", duplicateEvent);
        // duplicateEvent = duplicateEvent?.images?.filter(
        //   (item: any) => item?.type !== "first_image"
        // );
        setEventData(response?.data?.data);
        setColors(response?.data?.data?.color);
        setLoader2(false);
      })
      .catch((error) => {
        setLoader2(false);
      });
  };
  console.log("eventData", eventData);
  //  API for subscription Plan
  useEffect(() => {
    getEventHandler();
    // eslint-disable-next-line
  }, []);

  // notification handler
  const openNotification = (message: any, description: any) => {
    notification.open({
      message: message,
      description: description,
    });
  };

  // handler for multiple files
  const uploadProps = useMemo(
    () => ({
      beforeUpload: (file: any) => {
        if (file?.type?.split("/")[0] === "image") {
          setFileList([...fileList, file]);
          return false;
        } else {
          openNotification("Error", "Upload image file");
        }
      },
      onRemove: (file: any) => {
        if (fileList.some((item: any) => item.uid === file.uid)) {
          setFileList((fileList: any) =>
            fileList.filter((item: any) => item.uid !== file.uid)
          );
          return true;
        }
        return false;
      },
    }),
    [fileList]
  );

  // handler for single files
  const uploadProps2 = useMemo(
    () => ({
      beforeUpload: (file: any) => {
        if (file?.type?.split("/")[0] === "image") {
          setFileList2((state): any => [file]);
          getBase64(file);
          return false;
        } else {
          openNotification("Error", "Upload image file");
        }
      },
      onRemove: (file: any) => {
        if (fileList2.some((item: any) => item.uid === file.uid)) {
          setFileList2((fileList) =>
            fileList.filter((item: any) => item.uid !== file.uid)
          );
          return true;
        }
        return false;
      },
    }),
    [fileList2]
  );

  // delete image handler to remove old images
  const deleteImageHandler = (id: any) => {
    let duplicate = { ...eventData };
    duplicate = duplicate?.images?.filter((img: any) => img.id !== id);
    setEventData({ ...eventData, images: duplicate });
    // state to store previous images id to remove from DB
    setDeletedImagesIds([...deletedImagesIds, id]);
  };

  // onFinish handler
  const onFinish = (values: any) => {
    setLoader(true);
    if (colors?.length === 0) {
      setColorsValidation(true);
      setLoader(false);
      openNotification("Error", "Please Select Color");
    } else {
      setColorsValidation(false);
      // key to save all image
      // config
      const config = {
        headers: {
          // Authorization: `Bearer ${JSON.parse(token)}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const fileList_tm = fileList;
      // payload
      let payload: any = {
        name: values?.name !== "" ? values?.name : eventData?.name?.en,
        description:
          values?.description !== ""
            ? values?.description
            : eventData?.description,
        short_description:
          values?.short_description !== ""
            ? values?.short_description
            : eventData?.short_description,
        price: values?.price !== "" ? values?.price : eventData?.price,
        category:
          values?.category !== "" ? values?.category : eventData?.category,
        size: ['none'],
        // size: 'values?.size !== "" ? [...values?.size] : eventData?.size',
        tag_num: values?.tag_num,
        color: colors,
      };
      if (fileList2?.length !== 0) {
        payload = { ...payload, first_image: fileList2[0] };
      }
      if (fileList?.length > 0) {
        payload = { ...payload, images: fileList_tm };
      }
      if (deletedImagesIds?.length > 0) {
        payload = { ...payload, images_to_delete: deletedImagesIds };
      }
      // API call
      axios
        .post(
          `${process.env.REACT_APP_FRONTED_URL}/products/${params?.id}?_method=PUT&locale=en`,
          payload,
          config
        )
        .then(() => {
          setLoader(false);
          navigate("/products");
          openNotification("Success", "Event Updated Successfully");
        })
        .catch((error) => {
          setLoader(false);
          openNotification("Error", "");
        });
    }
  };

  return (
    <>
      <div className="header">
        <h1 className="text-[18px] font-semibold pb-4">Edit Status</h1>
      </div>
      {loader2 ? (
        <div className="event_spin">
          <Spin />
        </div>
      ) : (
        <Form
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            name: eventData?.name,
            price: eventData?.price,
            short_description: eventData?.short_description,
            description: eventData?.description,
            images: eventData?.images,
            size: eventData?.size,
            category: eventData?.category,
            tag_num: eventData?.tag_num,
          }}
        >
          <div className="cover_image">
            <Form.Item name="first_image" label="Profile Photo">
              <div>
                <div>
                  <Image
                    width={200}
                    height={200}
                    src={
                      fileList2?.length !== 0
                        ? updatedCoverPhoto
                        : eventData?.first_image
                    }
                    className="rounded-10"
                  />
                </div>
                <div style={{ paddingTop: "20px" }}>
                  <Upload
                    name="first_image"
                    multiple
                    {...uploadProps2}
                    fileList={fileList2}
                  >
                    <Button icon={<UploadOutlined />}>Upload Image</Button>
                  </Upload>
                </div>
              </div>
            </Form.Item>
          </div>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please enter your Name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="price"
            label="Price"
            rules={[{ required: true, message: "Please enter your Price" }]}
          >
            <Input type="number" className="font-montseraat" />
          </Form.Item>

          <Form.Item
            name="category"
            label="Category"
            rules={[
              {
                required: true,
                message: "Please select category",
              },
            ]}
          >
            <Select
              mode="multiple"
              size={size}
              placeholder="Please select"
              defaultValue={[]}
              onChange={handleChange}
              style={{ width: "100%" }}
              options={[
                {
                  value: "Bridal",
                  label: "Bridal",
                },
                {
                  value: "Classic",
                  label: "Classic",
                },
                {
                  value: "Mens",
                  label: "Mens",
                },
                {
                  value: "Fashion",
                  label: "Fashion",
                },
              ]}
            />
          </Form.Item>
          {/* <Form.Item
            name="size"
            label="Size"
            rules={[
              {
                required: false,
                message: "Please select size",
              },
            ]}
          >
            <Input />
          </Form.Item> */}
          <Form.Item
            name="tag_num"
            label="Tag No."
            // rules={[
            //   {
            //     required: false,
            //     message: "Please select size",
            //   },
            // ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="color"
            label="Color"
            rules={[
              {
                required: false,
                message: "Please select color",
              },
            ]}
            className="flex items-end colors-picker-container"
          >
            {colors?.map((item: any, index: any) => (
              <div>
                <ColorPicker
                  open={open === index}
                  onOpenChange={(e) => {
                    if (e === true) {
                      setOpen(index);
                    } else {
                      setOpen(false);
                    }
                    console.log("item", item, e);
                  }}
                  showText={() => (
                    <DownOutlined
                      rotate={open === index ? 180 : 0}
                      style={{
                        color: "rgba(0, 0, 0, 0.25)",
                      }}
                    />
                  )}
                  value={item}
                  className=""
                  onChange={(e) => {
                    let duplicate = [...colors];
                    duplicate[index] = e.toHexString();
                    setColors(duplicate);
                    setColorsValidation(false);
                  }}
                />
                <div className="flex justify-center">
                  <DeleteOutlined
                    className="cursor-pointer text-[18px] mt-1 mx-2"
                    onClick={() => {
                      let duplicate = colors?.filter(
                        (color: any, colorIndex: any) => colorIndex !== index
                      );
                      setColors(duplicate);
                    }}
                  />
                </div>
              </div>
            ))}
            <Button
              className="ml-2"
              onClick={() => {
                setColors([...colors, "#707d91"]);
                setColorsValidation(false);
              }}
            >
              +
            </Button>
          </Form.Item>
          {colorsValidation && (
            <div>
              <div
                id="color_help"
                className="ant-form-item-explain ant-form-item-explain-connected css-dev-only-do-not-override-2i2tap"
                role="alert"
              >
                <div className="ant-form-item-explain-error text-[#ff4d4f]">
                  Please select color
                </div>
              </div>
              <div style={{ width: "0px", height: "24px" }}></div>
            </div>
          )}
          <Form.Item
            name="short_description"
            label="Short Description"
            rules={[
              {
                required: true,
                message: "Please enter your short Description",
              },
            ]}
          >
            <Input.TextArea rows={5} className="font-montseraat" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please enter your Description" },
            ]}
          >
            <ReactQuill
              theme="snow"
              className={
                "ant-input ant-input-status-error css-dev-only-do-not-override-12jzuas border-radius-2 mb-[42px] h-[100px] font-montseraat"
              }
              style={{ padding: "0px" }}
            />
          </Form.Item>
          <Form.Item
            name="images"
            label="Images"
            // rules={[
            //   { required: true, message: "Please upload product images" },
            // ]}
          >
            <Upload name="images" multiple {...uploadProps} fileList={fileList}>
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>
          <div>
            <div className="header">
              <p>Gallery</p>
            </div>
            <div
              className="px-2"
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {eventData?.images?.length > 0 &&
              eventData?.images !== undefined ? (
                eventData?.images?.map((img: any, index: any) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "210px",
                    }}
                  >
                    <div className="pt-5 pb-3">
                      <Image
                        width={200}
                        height={200}
                        src={img?.path}
                        className="rounded-10"
                      />
                    </div>
                    <div>
                      <Button onClick={() => deleteImageHandler(img.id)}>
                        Delete
                      </Button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="">No Images</div>
              )}
            </div>
          </div>
          <Form.Item>
            <div className="form_submit flex justify-end">
              <Button
                className="back_button mx-2"
                onClick={() => navigate("/products")}
              >
                Back
              </Button>
              <Button htmlType="submit" className="bg-[#620926] text-white">
                {loader ? "wait..." : "Save"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
export default EventForm;
