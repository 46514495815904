import { Button, Form, notification, Select, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";

const EditContact = () => {
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(true);
  const [editData, setEditData]: any = useState({});

  const openNotification = (message: any, description: any) => {
    notification.open({
      message: message,
      description: description,
    });
  };
  const navigate = useNavigate();
  const params = useParams();
  const getContactHandler = () => {
    setLoader2(true);
    axios
      .get(
        `${process.env.REACT_APP_FRONTED_URL}/contact-us-requests/${params?.id}?locale=en`
      )
      .then((response) => {
        setEditData(response?.data?.data);
        setLoader2(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader2(false);
      });
  };
  useEffect(() => {
    getContactHandler();
    // eslint-disable-next-line
  }, []);

  // onFinish handler
  const onFinish = (values: any) => {
    setLoader(true);
    const payload = {
      status: values.status,
    };
    if (values.status === "Open") {
      openNotification(
        "Warning",
        "You have to update the status to 'In Progress' or to 'Closed'"
      );
      setLoader(false);
    } else {
      axios
        .post(
          `${process.env.REACT_APP_FRONTED_URL}/update-status/${params?.id}?_method=PUT&locale=en`,
          payload
        )
        .then(() => {
          navigate("/");
          setLoader(false);
          openNotification("Success", "Contact Status Updated Successfully");
        })
        .catch((error) => {
          setLoader(false);
          openNotification("Error", "");
        });
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="header">
        <h1 className="text-[18px] font-semibold pb-4">Edit Status</h1>
      </div>
      {loader2 ? (
        <div className="event_spin flex justify-center pt-20">
          <Spin />
        </div>
      ) : (
        <div className="form_container">
          <Form
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              status: editData?.status,
            }}
          >
            <Form.Item
              name="status"
              label="Status"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={[
                  { value: "Open", label: "Open" },
                  {
                    value: "In progress",
                    label: "In progress",
                  },
                  {
                    value: "Closed",
                    label: "Closed",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item>
              <div className="form_submit flex justify-end">
                <Button
                  className="back_button mx-2"
                  onClick={() => navigate("/")}
                >
                  Cancel
                </Button>
                <Button htmlType="submit">
                  {loader ? "...Wait" : "Update"}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
};
export default EditContact;
