import { Button, Form, Checkbox, Input, Spin, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
// import { useCookies } from "react-cookie";
import Love from "../../assets/login/love.png";
import axios from "axios";
import cookie from "react-cookies";

const Login = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  // const [setCookie] = useCookies(["token"]);

  const onChange = (newName: any) => {
    cookie.save("token", newName, { path: "/" });
  };

  // notification handler
  const openNotification = (message: any, description: any) => {
    notification.open({
      message: message,
      description: description,
    });
  };
  // onFinish
  const onFinish = (values: any) => {
    setLoader(true);
    const payload = {
      email: values.email,
      password: values.password,
    };
    axios
      .post(`${process.env.REACT_APP_FRONTED_URL}/login?locale=en`, payload)
      .then((response) => {
        onChange(
          response?.data?.data?.token &&
            JSON.stringify(response?.data?.data?.token)
        );
        localStorage.setItem("login", "true");
        setLoader(false);
        openNotification("Success", "Your are loggedIn Successfully");
        navigate("/");
      })
      .catch((error) => {
        console.log("error", error);
        setLoader(false);
        openNotification("Error", "Incorrect email and password");
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-[#fff]">
      <div
        className="flex justify-between lg:flex-row-reverse flex-col max-w-screen-lg rounded-[15px] m-0 m-auto "
        style={{ boxShadow: "0 0 40px rgba(0, 0, 0, 0.16)" }}
      >
        <div className="flex lg:max-w-screen-xl  min-h-full justify-center items-center bg-[#17202A] lg:w-[470px]   lg:rounded-r-[15px]">
          <img
            src={require("../../assets/logo/logo.png")}
            alt=""
            className="h-[35px]"
          />
          <h6 className="text-[#fff] lg:text-[30px] text-[25px] tracking-tight pl-2">
            NEW MASHOOM
          </h6>
        </div>
        <Form
          name="login-form"
          className=""
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <p className="lg:text-[22px] text-[16px] font-semibold text-[#444] text-center ">
            LOGIN
          </p>

          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please enter your email!" }]}
          >
            <Input
              placeholder="Email"
              className="text-[14px] text-[#444] font-normal font-montseraat"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              placeholder="Password"
              className="text-[14px] text-[#444] font-normal font-montseraat"
            />
          </Form.Item>
          {/* <div>
            <p className="text-[14px] text-[#ff4d4f] mt-0">{showError}</p>
          </div> */}
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              className="w-full text-[16px] text-[#fff] font-medium bg-[#001529]"
            >
              LOGIN
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
