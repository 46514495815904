import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Dropdown, Space, Avatar } from "antd";
import { Link } from "react-router-dom";
import Cookie from "react-cookies";

const MenuButton = () => {
  const Navigate = useNavigate();
  const logout = () => {
    Cookie.remove("token");
    Navigate("/login");
  };
  const items = [
    {
      label: (
        <Link
          onClick={() => {
            localStorage.removeItem("login");
            logout();
          }}
        >
          Logout
        </Link>
      ),
      key: "0",
    },
  ];

  return (
    <>
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
        className="px-4"
      >
        <Link className="text-[15px] font-semibold">
          <Space>
            Admin
            <Avatar
              className="h-[36px] w-[36px] bg-[#888]"
              icon={<UserOutlined />}
            />
          </Space>
        </Link>
      </Dropdown>
    </>
  );
};
export default MenuButton;
