import {
  Table,
  Space,
  Pagination,
  Form,
  Button,
  Input,
  notification,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "antd";
import { EditOutlined, MailOutlined } from "@ant-design/icons";
import { getDateHandler, month, date } from "../../utils/date";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";

interface DataType {
  key: string;
  name: string;
  email: string;
  orderid: number;
}

const UserTable = () => {
  const naviagte = useNavigate();
  // contact data usestate
  const [contactData, setContactData]: any = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDetail, setUserDetail]: any = useState({});
  const [validation, setValidation] = useState(false);
  const [message, setMessage] = useState("");

  // openNotification
  const openNotification = (message: any, description: any) => {
    notification.open({
      message: message,
      description: description,
    });
  };
  // method for API call
  const getContactHandler = (page: any) => {
    setLoader(true);
    //axios call
    axios
      .get(
        `${process.env.REACT_APP_FRONTED_URL}/contact-us-requests?locale=en&page=${page}&booking=0`
      )
      .then((response) => {
        setContactData(response?.data?.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  //  API for contact
  useEffect(() => {
    getContactHandler(currentPage);
    // eslint-disable-next-line
  }, []);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      key: "phone",
      dataIndex: "phone",
    },
    {
      title: "Message",
      key: "message",
      dataIndex: "message",
    },
    // {
    //   title: "Status",
    //   key: "status",
    //   dataIndex: "status",
    // },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (data: any) => (
        <Space size="middle">
          {getDateHandler(data) +
            "-" +
            month[date?.getMonth()] +
            "-" +
            date?.getFullYear()}
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (data) => (
        <div>
          <MailOutlined
            className="cursor-pointer text-[20px] px-3"
            onClick={() => {
              setUserDetail(data);
              setIsModalOpen(true);
            }}
          />
        </div>
      ),
    },
  ];

  // onFinish handler
  const onFinish = () => {
    setLoader2(true);
    const payload = {
      reply: message,
    };
    if (message === "" || message === "<p><br></p>") {
      setValidation(true);
      setLoader2(false);
    } else {
      axios
        .post(
          `${process.env.REACT_APP_FRONTED_URL}/reply-to-request/${userDetail?.id}?locale=en`,
          payload
        )
        .then(() => {
          setLoader2(false);
          openNotification("Success", "Message Sent Successfully");
        })
        .catch((error) => {
          setLoader2(false);
          openNotification("Error", "");
        });
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  console.log("userDetail", userDetail);
  return (
    <>
      <div className="pt-2 pb-5 flex justify-between">
        <h1 className="text-[18px] font-semibold">Appointments</h1>
      </div>
      <Table
        columns={columns}
        dataSource={contactData?.data}
        pagination={false}
        loading={loader}
        scroll={{ x: 1023 }}
      />
      {/* Pagination */}
      <Pagination
        defaultPageSize={currentPage}
        showSizeChanger={false}
        current={currentPage}
        pageSize={
          contactData !== undefined && contactData?.length !== 0
            ? contactData?.per_page
            : 1
        }
        total={contactData?.total}
        onChange={(e) => {
          setCurrentPage(e);
          getContactHandler(e);
        }}
        className="pagination"
      />
      {/* edit Status modal*/}
      <Modal open={isModalOpen} footer={null} onCancel={handleCancel}>
        <div className="view_content">
          <h2 className="font-semibold">Send Message</h2>
          <div className="pt-5">
            <Input
              defaultValue={userDetail?.email}
              value={userDetail?.email}
              disabled
              className="py-2 !rounded-[5px] font-montseraat mb-2"
            />
            <Form>
              <Form.Item
                name="message"
                label=""
                rules={[{ required: false, message: "This field is required" }]}
              >
                <ReactQuill
                  theme="snow"
                  // value={message}
                  onChange={(e: any) => {
                    setMessage(e);
                    if (e === "" || e === "<p><br></p>") {
                      setValidation(true);
                    } else {
                      setValidation(false);
                    }
                  }}
                  className={`mb-[40px] h-[100px] font-montseraat`}
                  style={{ padding: "0px" }}
                />
                <p className="text-red-500 pt-2">
                  {validation ? "Enter Your Message" : ""}
                </p>
              </Form.Item>
              <Form.Item>
                <div className="form_submit flex justify-end">
                  <Button
                    // htmlType="submit"
                    onClick={() => onFinish()}
                    className="border-[#620926] bg-[#620926] text-white"
                  >
                    {loader2 ? "...Wait" : "Send"}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default UserTable;
