import "./style/index.css";
// all routes
import AllRoutes from "./routes";
import "react-quill/dist/quill.snow.css";
function App() {
  return (
    <div>
      <AllRoutes />
    </div>
  );
}

export default App;
