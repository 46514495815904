  //  date to define
  export const date = new Date();
  export const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

export const getDateHandler = (date: any) => {
  const d = new Date(date);
  const day = d.getDate();
  return day;
};
