import { useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, theme } from "antd";
import MenuButton from "../dropdown/menu";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const { Header, Sider, Content } = Layout;

const Layout1 = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  // function to get Tabs Props
  function getItem(label, key, icon, children, theme) {
    return {
      key,
      icon,
      children,
      label,
      theme,
    };
  }
  // handler to show active part
  const activeTabHandler = (acitveRoute) => {
    switch (acitveRoute) {
      case "/":
        return "1";
      case "/edit-appointment/" + location?.pathname?.split("/")[2]:
        return "1";
      case "/products":
        return "2";
      case "/add-product":
        return "2";
      case "/edit-product/" + location?.pathname?.split("/")[2]:
        return "2";
      case "/contacts":
        return "3";
      default:
        return "1";
    }
  };
  // sidenav items
  const items = [
    getItem(<Link to="/">Appointments</Link>, "1", <CalendarOutlined />),
    getItem(<Link to="/products">Products</Link>, "2", <CalendarOutlined />),
    // getItem(<Link to="/contacts">Contacts</Link>, "3", <TeamOutlined />),
  ];
  console.log("location?.pathname", location?.pathname?.split("/")[2]);
  return (
    <Layout style={{ height: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical" />
        <div className="py-4 flex items-center justify-center">
          {!collapsed ? (
            <>
              <img
                src={require("../../assets/logo/logo.png")}
                alt=""
                className="h-[35px] "
              />
              <h6 className="text-center text-[15px] pl-1 text-[#fff] font-semibold">
                NEW MASHOOM
              </h6>
            </>
          ) : (
            <img
              src={require("../../assets/logo/logo.png")}
              alt=""
              className="h-[35px] pl-3 "
            />
          )}
        </div>
        <Menu
          theme="dark"
          className="text-[14px] pt-7"
          mode="inline"
          defaultSelectedKeys={[activeTabHandler(location?.pathname)]}
          items={items}
          selectedKeys={[activeTabHandler(location?.pathname)]}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "20px",
              width: 64,
              height: 64,
            }}
          />
          <MenuButton />
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            overflowY: "scroll",
            background: colorBgContainer,
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Layout1;
