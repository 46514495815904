import { useState, useMemo } from "react";
import {
  Button,
  Form,
  Input,
  notification,
  Upload,
  Select,
  ColorPicker,
} from "antd";
import {
  UploadOutlined,
  DownOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";

const EventForm = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [fileList, setFileList]: any = useState([]);
  const [fileList2, setFileList2]: any = useState([]);
  const [size, setSize]: any = useState("middle");
  const [open, setOpen] = useState(false);
  const [colors, setColors]: any = useState(["#707d91"]);
  const [colorsValidation, setColorsValidation] = useState(false);

  // const handleSizeChange = (e: any) => {
  //   setSize(e.target.value);
  // };
  const handleChange = (value: string | string[]) => {
    console.log(`Selected: ${value}`);
  };

  // notification handler
  const openNotification = (message: any, description: any) => {
    notification.open({
      message: message,
      description: description,
    });
  };

  // handler for multiple files
  const uploadProps = useMemo(
    () => ({
      beforeUpload: (file: any) => {
        if (file?.type?.split("/")[0] === "image") {
          setFileList([...fileList, file]);
          return false;
        } else {
          openNotification("Error", "Upload image file");
        }
      },
      onRemove: (file: any) => {
        if (fileList.some((item: any) => item.uid === file.uid)) {
          setFileList((fileList: any) =>
            fileList.filter((item: any) => item.uid !== file.uid)
          );
          return true;
        }
        return false;
      },
    }),
    [fileList]
  );

  // handler for single files
  const uploadProps2 = useMemo(
    () => ({
      beforeUpload: (file: any) => {
        if (file?.type?.split("/")[0] === "image") {
          setFileList2((state: any) => [file]);
          return false;
        } else {
          openNotification("Error", "Upload image file");
        }
      },
      onRemove: (file: any) => {
        if (fileList2.some((item: any) => item.uid === file.uid)) {
          setFileList2((fileList: any) =>
            fileList.filter((item: any) => item.uid !== file.uid)
          );
          return true;
        }
        return false;
      },
    }),
    [fileList2]
  );

  // dateHandler to update the format
  const dateHandler = (selectedDate: any) => {
    let date = new Date(selectedDate);
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1) +
      "-" +
      (date.getDate() < 10 ? `0${date.getDate()}` : date.getDate())
    );
  };

  const onFinish = (values: any) => {
    setLoader(true);
    // payload
    const fileList_tm = fileList;
    if (colors?.length === 0) {
      setColorsValidation(true);
      openNotification("Error", "Please Select Color");
      setLoader(false);
    } else {
      setColorsValidation(false);
      console.log("values", values);
      // success scenario
      const payload = {
        name: values?.name,
        description: values?.description,
        price: values?.price,
        category: values?.category,
        color: colors,
        size: ['none'],
        // size: [values.size],
        short_description: values?.short_description,
        first_image: values?.first_image?.file,
        tag_num: values?.tag_num,
        // images: fileList_tm,
      };
      // config
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_FRONTED_URL}/products?locale=en`,
          payload,
          config
        )
        .then(() => {
          setLoader(false);
          navigate("/products");
          openNotification("Success", "Product added Successfully");
        })
        .catch((error) => {
          setLoader(false);
          openNotification("Error", "");
        });
    }
  };

  return (
    <>
      <div className="header pb-4">
        <h1 className="text-[18px] font-semibold">Add Product</h1>
      </div>
      <div className="form_container">
        <Form layout="vertical" onFinish={onFinish}>
          <div className="cover_image">
            <Form.Item
              name="first_image"
              label="Profile Photo"
              rules={[
                { required: true, message: "Please upload your cover photo" },
              ]}
            >
              <Upload
                name="first_image"
                multiple
                {...uploadProps2}
                fileList={fileList2}
              >
                <Button icon={<UploadOutlined />}>Upload Image</Button>
              </Upload>
            </Form.Item>
          </div>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please enter your Name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="price"
            label="Ticket Price"
            rules={[{ required: true, message: "Please enter your Price" }]}
          >
            <Input type="number" className="font-montseraat" />
          </Form.Item>
          <Form.Item
            name="category"
            label="Category"
            rules={[
              {
                required: true,
                message: "Please select category",
              },
            ]}
          >
            <Select
              mode="multiple"
              size={size}
              placeholder="Please select"
              defaultValue={[]}
              onChange={handleChange}
              style={{ width: "100%" }}
              options={[
                {
                  value: "Bridal",
                  label: "Bridal",
                },
                {
                  value: "Classic",
                  label: "Classic",
                },
                {
                  value: "Mens",
                  label: "Mens",
                },
                {
                  value: "Fashion",
                  label: "Fashion",
                },
              ]}
            />
          </Form.Item>
          {/* <Form.Item
            name="size"
            label="Size"
            rules={[
              {
                required: false,
                message: "Please select size",
              },
            ]}
          >
            <Input />
          </Form.Item> */}
          <Form.Item
            name="tag_num"
            label="Tag No."
            // rules={[
            //   {
            //     required: false,
            //     message: "Please select size",
            //   },
            // ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="color"
            label="Color"
            rules={[
              {
                required: false,
                message: "Please select color",
              },
            ]}
            className="flex items-end colors-picker-container"
          >
            {colors?.map((item: any, index: any) => (
              <div>
                <ColorPicker
                  open={open === index}
                  onOpenChange={(e) => {
                    if (e === true) {
                      setOpen(index);
                    } else {
                      setOpen(false);
                    }
                    console.log("item", item, e);
                  }}
                  showText={() => (
                    <DownOutlined
                      rotate={open === index ? 180 : 0}
                      style={{
                        color: "rgba(0, 0, 0, 0.25)",
                      }}
                    />
                  )}
                  className=""
                  onChange={(e: any) => {
                    let duplicate = [...colors];
                    duplicate[index] = e.toHexString();
                    setColors(duplicate);
                    setColorsValidation(false);
                  }}
                />
                <div className="flex justify-center">
                  <DeleteOutlined
                    className="cursor-pointer text-[18px] mt-1 mx-2"
                    onClick={() => {
                      let duplicate = colors?.filter(
                        (color: any, colorIndex: any) => colorIndex !== index
                      );
                      console.log("colors", duplicate);
                      setColors(duplicate);
                    }}
                  />
                </div>
              </div>
            ))}
            <Button
              className="ml-2"
              onClick={() => {
                setColors([...colors, "#707d91"]);
                setColorsValidation(false);
              }}
            >
              +
            </Button>
          </Form.Item>
          {colorsValidation && (
            <div>
              <div
                id="color_help"
                className="ant-form-item-explain ant-form-item-explain-connected css-dev-only-do-not-override-2i2tap"
                role="alert"
              >
                <div className="ant-form-item-explain-error text-[#ff4d4f]">
                  Please select color
                </div>
              </div>
              <div style={{ width: "0px", height: "24px" }}></div>
            </div>
          )}
          <Form.Item
            name="short_description"
            label="Short Description"
            rules={[
              {
                required: true,
                message: "Please enter your short Description",
              },
            ]}
          >
            <Input.TextArea rows={5} className="font-montseraat" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please enter your Description" },
            ]}
          >
            <ReactQuill
              theme="snow"
              className={
                "ant-input ant-input-status-error css-dev-only-do-not-override-12jzuas border-radius-2 mb-[42px] h-[100px] font-montseraat"
              }
              style={{ padding: "0px" }}
            />
          </Form.Item>
          <Form.Item
            name="images"
            label="Images"
            // rules={[
            //   { required: true, message: "Please upload product images" },
            // ]}
          >
            <Upload name="images" multiple {...uploadProps} fileList={fileList}>
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <div className="form_submit flex justify-end">
              <Button
                className="back_button mx-2"
                onClick={() => navigate("/products")}
              >
                Back
              </Button>
              <Button htmlType="submit" className="bg-[#620926] text-white">
                {loader ? "wait..." : "Save"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export default EventForm;
