import { Routes, Route, useNavigate } from "react-router-dom";
import Layout from "../shared/layout";
import { useEffect, useState } from "react";
// cookie
import cookie from "react-cookies";
// login
import Login from "../component/login/login";
// user
import Contact from "../component/contacts/contacts";
// shop
import Appointment from "../component/appointments/appointments";
import EditAppointment from "../component/appointments/editAppointment";
// products
import Products from "../component/products/products";
import CreateProduct from "../component/products/createProducts";
import EditProduct from "../component/products/editProduct";

const AllRoutes = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  // useEffect for loggedIn user to check
  useEffect(() => {
    let token = cookie.load("token");
    if (token !== undefined) {
      // navigate(-1);
      setLoggedIn(true);
    } else {
      navigate("/login");
      setLoggedIn(false);
    }
    // eslint-disable-next-line
  }, [cookie.load("token")]);
  return (
    <>
      {/* login page */}
      {!loggedIn ? (
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes>
      ) : (
        <Layout>
          <Routes>
            <Route path="/" element={<Appointment />} />
            <Route path="/edit-appointment/:id" element={<EditAppointment />} />
            <Route path="/contacts" element={<Contact />} />
            <Route path="/add-product" element={<CreateProduct />} />
            <Route path="/edit-product/:id" element={<EditProduct />} />
            <Route path="/products" element={<Products />} />
          </Routes>
        </Layout>
      )}
    </>
  );
};
export default AllRoutes;
